// There are many ways to pick a DOM node; here we get the form itself and the email
// input box, as well as the span element into which we will place the error message.
const form = document.getElementsByTagName('form')[0]

const user = document.getElementById('name')
const userError = document.querySelector('#name + span.error')

const email = document.getElementById('mail')
const emailError = document.querySelector('#mail + span.error')

const button = document.getElementById('button')

user.addEventListener('input', function (event) {
  // each time the user types check validity
  if (user.validity.valid) {
    // remove visible error message if valid
    emailError.textContent = '' // Reset the content of the message
    emailError.className = 'error' // Reset the visual state of the message
  } else {
    // show the error
    showError()
  }

  // check visibility of submit button
  checkButtonVisibility()
})

email.addEventListener('input', function (event) {
  // each time the user types check validity
  if (email.validity.valid) {
    // remove visible error message if valid
    emailError.textContent = '' // Reset the content of the message
    emailError.className = 'error' // Reset the visual state of the message
  } else {
    // show the error
    showError()
  }

  // check visibility of submit button
  checkButtonVisibility()
})

function showError(error) {
  if (error) {
    emailError.textContent = error
  } else if (user.validity.valueMissing) {
    // ff the name field is empty
    emailError.textContent = 'Bitte gib zuerst Deinen Namen an.'
  } else if (email.validity.valueMissing) {
    // ff the email field is empty
    emailError.textContent = 'Bitte gib Deine Email-Adresse ein.'
  } else if (email.validity.typeMismatch) {
    // ff the email address is not valid
    emailError.textContent = 'Deine Email-Adresse ist nicht korrekt.'
  } else {
    emailError.textContent = 'Es ist ein Fehler aufgetreten.'
  }

  // set the styling appropriately
  emailError.className = 'error'
}

function showSuccess() {
  emailError.textContent =
    'Email versendet. Check Dein Postfach und klicke auf den Aktivierungs-Link in der Mail. Zack- angemeldet. (Prüfe ggfs. auch Deinen Spam-Ordner)'
  emailError.className = 'success'
}

function checkButtonVisibility() {
  if (user.validity.valid && email.validity.valid) {
    button.classList.remove('disabled')
  } else {
    button.classList.add('disabled')
  }
}

button.addEventListener('click', function (event) {
  console.log(`register user "${user.value}" with email "${email.value}"`)
  register()
})

function register() {
  let data = {
    firstname: user.value,
    email: email.value
  }

  let url = 'https://ta87b9b9e.emailsys1a.net/188/7397/79be899dcc/subscribe/form.html?_g=1644495527'

  $.ajax({
    url: url,
    type: 'POST',
    data: data,
    headers: { Accept: 'application/json' },
    success: function (res) {
      console.log(res)
      showSuccess()
    },
    error: function (err) {
      console.error(err)
      showError(`Fehler bei der Anmeldung. Bitte nochmal versuchen.`)
    }
  })
}
